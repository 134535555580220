<template>
  <div style="height: 350px" class="w-100">
    <div class="container-loading w-100" v-show="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-if="!loading" style="height: 350px" class="w-100">
      <apexchart
        type="area"
        height="400"
        :options="chartOptions"
        :series="chartSeries"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { setupDB, getCachedData, cacheData } from "@/store/indexedDB.js";

export default {
  name: "chart",
  components: {
    apexchart: VueApexCharts,
  },

  data() {
    return {
      loading: true,
      chartOptions: {
        colors: ["#004392", "#4ea934"],
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 9,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        xaxis: {
          categories: [],
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        },
      },
      chartSeries: [
        {
          name: "Visualizações",
          data: [],
        },
        {
          name: "Conversões",
          data: [],
        },
      ],
    };
  },

  watch: {
    "$store.getters.dashboard": {
      async handler(newValue) {
        await setupDB()
          .then(async() => {
            this.loading = true;
            this.updateChartData(newValue);
          })
          .catch(async (error) => {
            this.loading = true;
            this.updateChartData(newValue);
          });
      },
      immediate: true, // Chama o handler assim que o watcher é definido
    },
  },

  methods: {
    async updateChartData(result) {
      var date = [];
      var views = [];
      var conversions = [];

      for (let i = 0; i < result.length; i++) {
        const element = result[i];
        date.push(moment(element.y).format("DD/MM/YYYY"));
        views.push(element.views);
        conversions.push(element.conversoes);
      }

      const cacheChartDate = await getCachedData("cacheChartDate");
      const cacheChartViews = await getCachedData("cacheChartViews");
      const cacheChartConversions = await getCachedData(
        "cacheChartConversions"
      );

      if (cacheChartDate && cacheChartViews && cacheChartConversions) {
        this.chartOptions.xaxis.categories = cacheChartDate;
        this.chartSeries[0].data = cacheChartViews;
        this.chartSeries[1].data = cacheChartConversions;
      }
      else{
        this.chartOptions.xaxis.categories = date;
        this.chartSeries[0].data = views;
        this.chartSeries[1].data = conversions;
      }
      cacheData("cacheChartDate", date);
      cacheData("cacheChartViews", views);
      cacheData("cacheChartConversions", conversions);
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.container-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: preload 5s forwards linear infinite alternate;
}
</style>
