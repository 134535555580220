<template>
  <BaseModal
    name="Modal-Terms"
    id="Modal-Terms"
    idModal="Modal-Terms"
    size="xl"
    title="Termos de Uso"
  >
    <iframe
      class="iframe"
      :src="termsURL"
      frameborder="0"
    ></iframe>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" @click="saveTerms">
        Aceitar Termos
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

export default {
  data() {
    return {
      terms: false,
      termsURL: process.env.URL_GREENN_TERMS
    };
  },

  methods: {
    saveTerms() {
      this.terms = true;

      setTimeout(() => {
        var data = {
          meta_key: "terms_accepted",
          meta_value: this.terms === true ? "true" : "false",
        };

        serviceSettings
          .create(data)
          .then((resp) => {
          })
          .catch((err) => {
            // console.log(err);
          });
      }, 200);

      localStorage.setItem("terms", this.terms);
      this.$bvModal.hide("Modal-Terms");
    },
  },
};
</script>

<style lang="scss" scoped>
.iframe {
  width: 100%;
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
