import Rest from '@/services/Rest';

/**
 * @typedef {ContratoService}
 */
export default class ContratoService extends Rest {
  /**
   * @type {String}
   */
  static resource = 'contrato'
}
