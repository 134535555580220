<template>
  <div>
    <!-- <div>
Formas de pegar os recursos e acessar os leads com rdr
Bem vindo, <b>{{ username }}</b>!<br />
Recurso: <b>gmembers</b>: {{ hasResources('gmembers') }}<br />
Recurso: <b>gmembers</b>: {{ getResources('gmembers') }}<br />
<a href="javascript:void(0);" @click="navigate">Leads (mesma aba)</a><br />
<a href="javascript:void(0);" @click="navigate(true)">Leads (nova aba)</a>
<b-button variant="primary" v-b-modal.modalSelectBlog>Trocar blog</b-button>
<BaseModalSelectBlog :blogs="blogs" @selected="selectBlog" />
</div> -->
    <div class="container">
      <!-- header -->
      <BaseHeader title="Dashboard">
        <router-link v-if="($store.getters.user.user.level === 'owner' || $store.getters.user.user.level === 'admin' || $store.getters.user.user.level == 'gerent_seller') && $store.getters.recursos.nstrategic" :to="'/dynamicRoute/estrategico'" style="text-decoration: none !important;">
          <button class="estrategic-button" style="display: flex; align-items: center; gap: 5px; justify-content: center;">
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-top: -4px;">
              <path d="M6 20V14" stroke="#FFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18 20V10" stroke="#FFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 20V4" stroke="#FFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Área estratégica
          </button>
        </router-link>
      </BaseHeader>

      <!-- banners -->
      <Banners />

      <!-- indicadores -->
      <div class="indicadores" data-anima="top">
        <div
          class="indicador"
          v-if="cards && this.$store.getters.user.user.level !== 'templater'"
        >
          <h3>
            {{
              calculatePercentage(
                cards.conversao.ontem,
                cards.conversao.anteontem
              ).porcentagem
            }}%
          </h3>
          <div class="flex-values">
            <span>Lead ontem</span>
            <svg
              v-if="
                calculatePercentage(
                  cards.conversao.ontem,
                  cards.conversao.anteontem
                ).diferenca_lead
              "
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 0.9375V15.9375M8.5 15.9375L16 8.4375M8.5 15.9375L1 8.4375"
                stroke="var(--red)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-else
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.8335 15.7819L8.8335 0.781861M8.8335 0.781861L1.3335 8.28186M8.8335 0.781861L16.3335 8.28186"
                stroke="var(--greenn)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <h2>{{ cards.conversao.ontem }} Leads</h2>
        </div>
        <div v-else class="indicador">
          <b-skeleton></b-skeleton>
          <div style="display: flex; gap: 20px">
            <b-skeleton width="75%" class="mt-4"></b-skeleton>
            <b-skeleton width="20%" class="mt-4"></b-skeleton>
          </div>
          <b-skeleton class="mt-4"></b-skeleton>
        </div>

        <div
          class="indicador"
          v-if="cards && this.$store.getters.user.user.level !== 'templater'"
        >
          <h3>
            {{
              calculatePercentageweek(
                cards.conversao.ontem,
                cards.conversao.semana
              ).porcentagem
                ? calculatePercentageweek(
                    cards.conversao.ontem,
                    cards.conversao.semana
                  ).porcentagem
                : 0
            }}%
          </h3>
          <div class="flex-values">
            <span>Leads 7 dias</span>
            <svg
              v-if="
                calculatePercentage(
                  cards.conversao.ontem,
                  cards.conversao.semana
                ).diferenca_lead
              "
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 0.9375V15.9375M8.5 15.9375L16 8.4375M8.5 15.9375L1 8.4375"
                stroke="var(--red)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-else
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.8335 15.7819L8.8335 0.781861M8.8335 0.781861L1.3335 8.28186M8.8335 0.781861L16.3335 8.28186"
                stroke="var(--greenn)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <h2>{{ cards.conversao.semana }} Leads</h2>
        </div>
        <div v-else class="indicador">
          <b-skeleton></b-skeleton>
          <div style="display: flex; gap: 20px">
            <b-skeleton width="75%" class="mt-4"></b-skeleton>
            <b-skeleton width="20%" class="mt-4"></b-skeleton>
          </div>
          <b-skeleton class="mt-4"></b-skeleton>
        </div>

        <div
          class="indicador"
          v-if="cards && this.$store.getters.user.user.level !== 'templater'"
        >
          <h3>
            {{
              calculatePercentage(cards.views.ontem, cards.views.anteontem)
                .porcentagem
                ? calculatePercentage(cards.views.ontem, cards.views.anteontem)
                    .porcentagem
                : 0
            }}%
          </h3>
          <div class="flex-values">
            <span>Acessos ontem</span>
            <svg
              v-if="
                calculatePercentage(cards.views.ontem, cards.views.anteontem)
                  .diferenca_lead
              "
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 0.9375V15.9375M8.5 15.9375L16 8.4375M8.5 15.9375L1 8.4375"
                stroke="var(--red)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-else
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.8335 15.7819L8.8335 0.781861M8.8335 0.781861L1.3335 8.28186M8.8335 0.781861L16.3335 8.28186"
                stroke="var(--greenn)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <h2>{{ cards.views.ontem }} Acessos</h2>
        </div>
        <div v-else class="indicador">
          <b-skeleton></b-skeleton>
          <div style="display: flex; gap: 20px">
            <b-skeleton width="75%" class="mt-4"></b-skeleton>
            <b-skeleton width="20%" class="mt-4"></b-skeleton>
          </div>
          <b-skeleton class="mt-4"></b-skeleton>
        </div>

        <div
          class="indicador"
          v-if="cards && this.$store.getters.user.user.level !== 'templater'"
        >
          <h3>
            {{
              calculatePercentageweek(cards.views.ontem, cards.views.semana)
                .porcentagem
                ? calculatePercentageweek(cards.views.ontem, cards.views.semana)
                    .porcentagem
                : 0
            }}%
          </h3>
          <div class="flex-values">
            <span>Acessos 7 dias</span>
            <svg
              v-if="
                calculatePercentage(cards.views.ontem, cards.views.semana)
                  .diferenca_lead
                  ? calculatePercentage(cards.views.ontem, cards.views.semana)
                      .diferenca_lead
                  : 0
              "
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 0.9375V15.9375M8.5 15.9375L16 8.4375M8.5 15.9375L1 8.4375"
                stroke="var(--red)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-else
              width="18"
              height="17"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.8335 15.7819L8.8335 0.781861M8.8335 0.781861L1.3335 8.28186M8.8335 0.781861L16.3335 8.28186"
                stroke="var(--greenn)"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <h2>{{ cards.views.semana }} Acessos</h2>
        </div>
        <div v-else class="indicador">
          <b-skeleton></b-skeleton>
          <div style="display: flex; gap: 20px">
            <b-skeleton width="75%" class="mt-4"></b-skeleton>
            <b-skeleton width="20%" class="mt-4"></b-skeleton>
          </div>
          <b-skeleton class="mt-4"></b-skeleton>
        </div>
      </div>

      <!-- grafico -->
      <div
        class="graph"
        data-anima="top"
        v-if="this.$store.getters.user.user.level !== 'templater'"
      >
        <h1 class="title-graph">Acessos e Conversões</h1>
        <p
          style="
            margin: 0;
            font-size: 12px;
            position: relative;
            top: 10px;
            color: var(--gray05);
          "
        >
          <svg
            @click="reload"
            v-b-tooltip="{
              title: 'Recarregar',
              placement: 'top',
            }"
            style="cursor: pointer"
            class="mr-2"
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 4V10H17"
              stroke="#8c8a97"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M20.49 15C19.84 16.8399 18.6096 18.4187 16.9842 19.4985C15.3588 20.5783 13.4265 21.1006 11.4784 20.9866C9.53038 20.8726 7.67216 20.1286 6.18376 18.8667C4.69536 17.6047 3.65743 15.8932 3.22637 13.9901C2.79531 12.0869 2.99448 10.0952 3.79386 8.31508C4.59325 6.53496 5.94954 5.06288 7.65836 4.12065C9.36717 3.17843 11.3359 2.81711 13.268 3.09116C15.2 3.3652 16.9906 4.25975 18.37 5.64001L23 10"
              stroke="#8c8a97"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Última atualização: {{ time_get }}
        </p>
        <div>
          <Chart v-if="graph && !isGreenn" />
          <Chart2 v-if="graph && isGreenn" />
          <div class="container-loading w-100" v-else>
            <!-- <b-spinner label="Loading..."></b-spinner> -->
            <b-skeleton width="100%" height="350px" class="mt-4"></b-skeleton>
          </div>
        </div>
      </div>
    </div>
    <ModalTerms />
  </div>
</template>

<script>
import RedirectService from "@/services/resources/RedirectService";
import DashboardService from "@/services/resources/DashboardService";

import BaseView from "@/template/BaseView.vue";
import Banners from "@/components/Dashboard/banners";
import Chart from "@/components/Dashboard/chart";
import Chart2 from "@/components/Dashboard/chart2";

const service = RedirectService.build();
const serviceDashboard = DashboardService.build();

import GlobalTokenService from "@/services/resources/GlobalTokenService";
const serviceGlobalToken = GlobalTokenService.build();

import ContratoService from "@/services/resources/ContratoService";
const serviceContrato = ContratoService.build();

import ResourceCount from "@/services/resources/RecursesService";
const resourceCount = ResourceCount.build();

import BackupService from "@/services/resources/BackupService";
const serviceBackup = BackupService.build();

import { setupDB, getCachedData, cacheData } from "@/store/indexedDB.js";
import ModalTerms from "../components/Dashboard/ModalTerms.vue";

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

import FlagService from "@/services/resources/FlagService";
const serviceFlag = FlagService.build();
export default {
  components: {
    Banners,
    BaseView,
    Chart,
    Chart2,
    ModalTerms,
  },

  data() {
    return {
      cards: null,
      graph: false,
      time_get: null,
      users: [],
      totalleads: 0,
      client: {
        width: 0,
      },
      contrato: "",
      count: 0
    };
  },
  created() {
    if(this.$route.query.route) {
      return this.$router.push(`/:dynamicRoute/${this.$route.query.route}`);
    };

    if(this.$route.query.rdrk){
      this.$router.replace({ path: this.$route.path });
    }
    this.getResources();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    if (
      this.$store.getters.user.user.level != "admin" &&
      this.$store.getters.user.user.level != "owner"
    ) {
      return;
    }

    this.checkIfHasExport();
    // this.getContrato()
  },

  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    username() {
      return this.$store.getters.user
        ? this.$store.getters.user.display_name
        : "";
    },
    blogs() {
      return this.$store.getters.blogs;
    },
    tags() {
      return this.$store.getters.tags;
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },

  methods: {
    async reload() {
      var time_get = await this.saveDate();
      cacheData("graph_time", time_get);
      this.time_get = time_get;
      this.getDashGraph();
    },
    saveDate() {
      const dataAtual = new Date();
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const dataFormatada = dataAtual.toLocaleDateString("pt-BR", options);

      return dataFormatada;
    },
    async getTerms() {
      var userData = this.$store.getters.user.user.level;
      var terms = localStorage.getItem("terms");
      let contracts = JSON.parse(sessionStorage.getItem("userContracts")) ?? [];

      if(!contracts.length){
        await this.getContrato();
        contracts = JSON.parse(sessionStorage.getItem("userContracts")) ?? [];
      }

      let active_trial = contracts.find(contract => contract.produto_id === 132);
      let freeProducts = [123, 132];

      let paidContracts = contracts.filter(contract => contract.produto_id >= 118 && !freeProducts.includes(contract.produto_id) && contract.status === 1);
      if (terms == "true") {
        return;
      }

      if ((userData == "owner" || userData == "admin") && (!active_trial || active_trial && paidContracts.length)) {
        serviceSettings
          .read(`meta/terms_accepted`)
          .then((resp) => {
            if (resp.meta_value == "true") {
              this.$bvModal.hide("Modal-Terms");
            } else if(!this.isGreenn){
              this.$bvModal.show("Modal-Terms");
            }
          })
          .catch((err) => {
          });
      }
    },
    getContrato() {
      return new Promise((resolve) => {
        resourceCount.read({id: "contrato"}).then((resp) => {
          sessionStorage.setItem("userContracts", JSON.stringify(resp));
          resolve(true);
        })
        .catch(() => {
          resolve(false);
        });
      });
    },
    checkIfHasExport() { 
      serviceBackup.create({ only_check: true }).then((resp) => {
        if (resp.permission != "dont_import" && this.$store.getters.user.user.tenant_subdomain != "adm") {
          this.$router.push({ name: "migration" });
        }

        if (resp.status == "dont" && resp.has_old_account == "true") {
          localStorage.setItem("has_imported", "true");
          return;
        }

        localStorage.removeItem("has_imported");
      });
    },
    async getDashData() {
      const cachedData = await getCachedData("cacheDashData");
      if (cachedData) {
        this.cards = cachedData;
        this.graph = true;
      }

      serviceDashboard.read({ id: "info" }).then((resp) => {
        cacheData("cacheDashData", resp);
        this.cards = resp;
      });
    },

    calculatePercentage(value1, value2) {
      var porcentagem_hoje = 0;
      this.totalleads = value1;

      if (value1 > value2) {
        porcentagem_hoje = (value1 * 100) / value1;
      }
      return {
        porcentagem: porcentagem_hoje,
      };
    },

    calculatePercentageweek(value1, value2) {
      let porcentagem_cada = 0;
      let porcentagem_semana = 0;

      porcentagem_cada = (value1 * 100) / value2;
      porcentagem_semana = 100 - porcentagem_cada;
      return {
        porcentagem: parseInt(porcentagem_semana),
      };
    },
    async getDashGraph() {
      await this.$store.dispatch("updateDashboardActions");
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    hasResources(key) {
      return this.$store.getters["hasResources"](key);
    },
    getResources(key) {
      return this.$store.getters["getResources"](key);
    },
    selectBlog(blog) {
      this.$store.dispatch("blogSelected", blog);
    },

    navigate(newTab = false) {
      service.create({}).then((resp) => {
        let url =
          "https://" +
          resp.domain +
          "/gadmin/?rdrk=" +
          resp.ck +
          "#/leads/lista";

        if (newTab) {
          window.open(url, "_blank");
        } else {
          window.location.href = url;
        }
      });
    },
    notify() {
      let notify = document.getElementById("dsadfjsahfjkhdsjjsdfkjsdhj");
      if (notify === null) {
        let recaptchaScript = document.createElement("script");
        recaptchaScript.setAttribute(
          "src",
          "https://gn.gdigital.com.br:3001/public/load.js"
        );
        recaptchaScript.setAttribute("id", "dsadfjsahfjkhdsjjsdfkjsdhj");
        document.head.appendChild(recaptchaScript);
      }
    },

    getGlobalToken(url) {
      serviceGlobalToken
        .search()
        .then((resp) => {
          const urlRedirect = `https://${url}?globalToken=${resp.token}`;
          window.location.href = urlRedirect;
          // window.location.href = `https://${url}?GlobalToken=${resp.token}`;
        })
        .catch((err) => {
          this.$grToast.toast("O domínio não é permitido!", {
            title: "Domínio",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },

    externalParams() {
      if (!this.$route.query.globalToken) {
        return;
      }
      if (this.$route.query.globalToken == "true") {
        var url = this.$route.query.getRedirectAfter;
        url = Buffer.from(url, "base64").toString("utf-8");
        if (url.includes(".greenn.club")) {
          this.getGlobalToken(url);
        } else {
          this.$grToast.toast("O domínio não é permitido!", {
            title: "Domínio",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      } else {
        this.$grToast.toast("O domínio não é permitido!", {
          title: "Domínio",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    Tenantflags(){
      if(this.$store.getters.user && this.$store.getters.user.user.tenant_id){
        serviceFlag.read(this.$store.getters.user.user.tenant_id).then(resp => {
          cacheData("fetchFlags", resp);
        })
      }
    },
  },
  async mounted() {
    var time_get = await this.saveDate();
    this.time_get = time_get;
    //MODAL TERMOS
    this.getTerms();
    setupDB()
      .finally(() =>{
          this.Tenantflags();
        })

    // this.$store.dispatch("fetchAll");
    // this.notify();
    this.getDashGraph();
    setupDB()
      .then(() => {
        this.getDashData();
      })
      .catch((error) => {
        this.getDashData();
      });
    this.externalParams();
  },
  watch: {
    "$store.getters.dashboard": {
      handler(newValue) {
        this.graph = true;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .indicadores {
    grid-template-columns: 1fr !important;
  }
}
.estrategic-button {
  color: #fff;
  background: var(--greenn);
  border: none;
  font-weight: 600;
  height: 50px;
  padding: 0 30px !important;
  border-radius: 10px !important;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    box-shadow: none;
  }
}
.graph {
  margin-top: 50px;
  margin-bottom: 75px;
  .title-graph {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
}

.indicadores {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
  .indicador {
    background: #ffffff;
    border: 0.5px solid #ededf0;
    border-radius: 10px;
    height: 145px;
    padding: 25px 35px;
    h3 {
      font-weight: 600;
      font-size: 20px;
      color: var(--gray05);
      margin-bottom: 12px;
    }
    h2 {
      font-weight: 600;
      font-size: 20px;
      color: var(--gray01);
    }
  }
  .flex-values {
    display: flex;
    justify-content: space-between;
    span {
      font-weight: 400;
      font-size: 16px;
      color: var(--gray01);
      margin-bottom: 10px;
    }
  }
}

.indicadores-loading {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
  .indicador {
    border: 0.5px solid #ededf0;
    border-radius: 10px;
    height: 145px;
  }
}
</style>
