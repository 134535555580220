var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container"},[_c('BaseHeader',{attrs:{"title":"Dashboard"}},[((_vm.$store.getters.user.user.level === 'owner' || _vm.$store.getters.user.user.level === 'admin' || _vm.$store.getters.user.user.level == 'gerent_seller') && _vm.$store.getters.recursos.nstrategic)?_c('router-link',{staticStyle:{"text-decoration":"none !important"},attrs:{"to":'/dynamicRoute/estrategico'}},[_c('button',{staticClass:"estrategic-button",staticStyle:{"display":"flex","align-items":"center","gap":"5px","justify-content":"center"}},[_c('svg',{staticStyle:{"margin-top":"-4px"},attrs:{"width":"18","height":"18","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6 20V14","stroke":"#FFF","stroke-width":"2.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M18 20V10","stroke":"#FFF","stroke-width":"2.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M12 20V4","stroke":"#FFF","stroke-width":"2.5","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" Área estratégica ")])]):_vm._e()],1),_c('Banners'),_c('div',{staticClass:"indicadores",attrs:{"data-anima":"top"}},[(_vm.cards && this.$store.getters.user.user.level !== 'templater')?_c('div',{staticClass:"indicador"},[_c('h3',[_vm._v(" "+_vm._s(_vm.calculatePercentage( _vm.cards.conversao.ontem, _vm.cards.conversao.anteontem ).porcentagem)+"% ")]),_c('div',{staticClass:"flex-values"},[_c('span',[_vm._v("Lead ontem")]),(
                _vm.calculatePercentage(
                  _vm.cards.conversao.ontem,
                  _vm.cards.conversao.anteontem
                ).diferenca_lead
              )?_c('svg',{attrs:{"width":"17","height":"17","viewBox":"0 0 17 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.5 0.9375V15.9375M8.5 15.9375L16 8.4375M8.5 15.9375L1 8.4375","stroke":"var(--red)","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]):_c('svg',{attrs:{"width":"18","height":"17","viewBox":"0 0 18 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.8335 15.7819L8.8335 0.781861M8.8335 0.781861L1.3335 8.28186M8.8335 0.781861L16.3335 8.28186","stroke":"var(--greenn)","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('h2',[_vm._v(_vm._s(_vm.cards.conversao.ontem)+" Leads")])]):_c('div',{staticClass:"indicador"},[_c('b-skeleton'),_c('div',{staticStyle:{"display":"flex","gap":"20px"}},[_c('b-skeleton',{staticClass:"mt-4",attrs:{"width":"75%"}}),_c('b-skeleton',{staticClass:"mt-4",attrs:{"width":"20%"}})],1),_c('b-skeleton',{staticClass:"mt-4"})],1),(_vm.cards && this.$store.getters.user.user.level !== 'templater')?_c('div',{staticClass:"indicador"},[_c('h3',[_vm._v(" "+_vm._s(_vm.calculatePercentageweek( _vm.cards.conversao.ontem, _vm.cards.conversao.semana ).porcentagem ? _vm.calculatePercentageweek( _vm.cards.conversao.ontem, _vm.cards.conversao.semana ).porcentagem : 0)+"% ")]),_c('div',{staticClass:"flex-values"},[_c('span',[_vm._v("Leads 7 dias")]),(
                _vm.calculatePercentage(
                  _vm.cards.conversao.ontem,
                  _vm.cards.conversao.semana
                ).diferenca_lead
              )?_c('svg',{attrs:{"width":"17","height":"17","viewBox":"0 0 17 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.5 0.9375V15.9375M8.5 15.9375L16 8.4375M8.5 15.9375L1 8.4375","stroke":"var(--red)","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]):_c('svg',{attrs:{"width":"18","height":"17","viewBox":"0 0 18 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.8335 15.7819L8.8335 0.781861M8.8335 0.781861L1.3335 8.28186M8.8335 0.781861L16.3335 8.28186","stroke":"var(--greenn)","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('h2',[_vm._v(_vm._s(_vm.cards.conversao.semana)+" Leads")])]):_c('div',{staticClass:"indicador"},[_c('b-skeleton'),_c('div',{staticStyle:{"display":"flex","gap":"20px"}},[_c('b-skeleton',{staticClass:"mt-4",attrs:{"width":"75%"}}),_c('b-skeleton',{staticClass:"mt-4",attrs:{"width":"20%"}})],1),_c('b-skeleton',{staticClass:"mt-4"})],1),(_vm.cards && this.$store.getters.user.user.level !== 'templater')?_c('div',{staticClass:"indicador"},[_c('h3',[_vm._v(" "+_vm._s(_vm.calculatePercentage(_vm.cards.views.ontem, _vm.cards.views.anteontem) .porcentagem ? _vm.calculatePercentage(_vm.cards.views.ontem, _vm.cards.views.anteontem) .porcentagem : 0)+"% ")]),_c('div',{staticClass:"flex-values"},[_c('span',[_vm._v("Acessos ontem")]),(
                _vm.calculatePercentage(_vm.cards.views.ontem, _vm.cards.views.anteontem)
                  .diferenca_lead
              )?_c('svg',{attrs:{"width":"17","height":"17","viewBox":"0 0 17 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.5 0.9375V15.9375M8.5 15.9375L16 8.4375M8.5 15.9375L1 8.4375","stroke":"var(--red)","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]):_c('svg',{attrs:{"width":"18","height":"17","viewBox":"0 0 18 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.8335 15.7819L8.8335 0.781861M8.8335 0.781861L1.3335 8.28186M8.8335 0.781861L16.3335 8.28186","stroke":"var(--greenn)","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('h2',[_vm._v(_vm._s(_vm.cards.views.ontem)+" Acessos")])]):_c('div',{staticClass:"indicador"},[_c('b-skeleton'),_c('div',{staticStyle:{"display":"flex","gap":"20px"}},[_c('b-skeleton',{staticClass:"mt-4",attrs:{"width":"75%"}}),_c('b-skeleton',{staticClass:"mt-4",attrs:{"width":"20%"}})],1),_c('b-skeleton',{staticClass:"mt-4"})],1),(_vm.cards && this.$store.getters.user.user.level !== 'templater')?_c('div',{staticClass:"indicador"},[_c('h3',[_vm._v(" "+_vm._s(_vm.calculatePercentageweek(_vm.cards.views.ontem, _vm.cards.views.semana) .porcentagem ? _vm.calculatePercentageweek(_vm.cards.views.ontem, _vm.cards.views.semana) .porcentagem : 0)+"% ")]),_c('div',{staticClass:"flex-values"},[_c('span',[_vm._v("Acessos 7 dias")]),(
                _vm.calculatePercentage(_vm.cards.views.ontem, _vm.cards.views.semana)
                  .diferenca_lead
                  ? _vm.calculatePercentage(_vm.cards.views.ontem, _vm.cards.views.semana)
                      .diferenca_lead
                  : 0
              )?_c('svg',{attrs:{"width":"17","height":"17","viewBox":"0 0 17 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.5 0.9375V15.9375M8.5 15.9375L16 8.4375M8.5 15.9375L1 8.4375","stroke":"var(--red)","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]):_c('svg',{attrs:{"width":"18","height":"17","viewBox":"0 0 18 17","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8.8335 15.7819L8.8335 0.781861M8.8335 0.781861L1.3335 8.28186M8.8335 0.781861L16.3335 8.28186","stroke":"var(--greenn)","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('h2',[_vm._v(_vm._s(_vm.cards.views.semana)+" Acessos")])]):_c('div',{staticClass:"indicador"},[_c('b-skeleton'),_c('div',{staticStyle:{"display":"flex","gap":"20px"}},[_c('b-skeleton',{staticClass:"mt-4",attrs:{"width":"75%"}}),_c('b-skeleton',{staticClass:"mt-4",attrs:{"width":"20%"}})],1),_c('b-skeleton',{staticClass:"mt-4"})],1)]),(this.$store.getters.user.user.level !== 'templater')?_c('div',{staticClass:"graph",attrs:{"data-anima":"top"}},[_c('h1',{staticClass:"title-graph"},[_vm._v("Acessos e Conversões")]),_c('p',{staticStyle:{"margin":"0","font-size":"12px","position":"relative","top":"10px","color":"var(--gray05)"}},[_c('svg',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              title: 'Recarregar',
              placement: 'top',
            }),expression:"{\n              title: 'Recarregar',\n              placement: 'top',\n            }"}],staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"width":"15","height":"15","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":_vm.reload}},[_c('path',{attrs:{"d":"M23 4V10H17","stroke":"#8c8a97","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M20.49 15C19.84 16.8399 18.6096 18.4187 16.9842 19.4985C15.3588 20.5783 13.4265 21.1006 11.4784 20.9866C9.53038 20.8726 7.67216 20.1286 6.18376 18.8667C4.69536 17.6047 3.65743 15.8932 3.22637 13.9901C2.79531 12.0869 2.99448 10.0952 3.79386 8.31508C4.59325 6.53496 5.94954 5.06288 7.65836 4.12065C9.36717 3.17843 11.3359 2.81711 13.268 3.09116C15.2 3.3652 16.9906 4.25975 18.37 5.64001L23 10","stroke":"#8c8a97","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" Última atualização: "+_vm._s(_vm.time_get)+" ")]),_c('div',[(_vm.graph && !_vm.isGreenn)?_c('Chart'):_vm._e(),(_vm.graph && _vm.isGreenn)?_c('Chart2'):_c('div',{staticClass:"container-loading w-100"},[_c('b-skeleton',{staticClass:"mt-4",attrs:{"width":"100%","height":"350px"}})],1)],1)]):_vm._e()],1),_c('ModalTerms')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }